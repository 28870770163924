
import './Footer.css'
import BarMobile from "../BarMobile/BarMobile";
import React from "react";

const Footer = (products) => {
    return (
        <>
            <BarMobile/>

        </>

    )
}

export default Footer
